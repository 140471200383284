import dayjs from 'dayjs'

export interface DayjsExtends {
  isSameOrBefore: (date?: string | number | CustomDayjs | Date | undefined) => boolean
  isSameOrAfter: (date?: string | number | CustomDayjs | Date | undefined) => boolean
}

export type CustomDayjs = dayjs.Dayjs & DayjsExtends

/**
 * 判断当前日期是否小于等于传入的日期
 */
dayjs.prototype.isSameOrBefore = function (date?: string | number | CustomDayjs | Date | undefined) {
  return this.isSame(date) || this.isBefore(date)
}
/**
 * 判断当前日期是否大于等于传入的日期
 */
dayjs.prototype.isSameOrAfter = function (date?: string | number | CustomDayjs | Date | undefined) {
  return this.isSame(date) || this.isAfter(date)
}

let currentDayjs = ''
function setCurrentDay(dayjs: CustomDayjs) {
  currentDayjs = dayjs.format()
}

function customDayjs(
  date?: dayjs.ConfigType | undefined,
  option?: dayjs.OptionType | undefined,
  locale?: string | undefined
) {
  if (!date && currentDayjs) {
    date = currentDayjs
  }
  return dayjs(date, option, locale)
}

// ConfigType时间格式
// OptionType操作格式,如get、diff等(maybe)
export function useDayjs(
  date?: dayjs.ConfigType | undefined,
  option?: dayjs.OptionType | undefined,
  locale?: string | undefined
) {
  return {
    setCurrentDay,
    dayjs: customDayjs as unknown as (
      date?: dayjs.ConfigType | undefined,
      option?: dayjs.OptionType | undefined,
      locale?: string | undefined
    ) => CustomDayjs,
    current: dayjs(date, option, locale) as unknown as CustomDayjs
  }
}
