import { useStorage, useApi } from '../../../hook'
import type { AppStoreType } from './type'
import { useI18n } from 'vue-i18n'

export function login(this: AppStoreType) {
  this.jwt = '123'
  useStorage().useLocalCache().setCache('pinia/auth/login', 'jwt', '123')
}

export async function getActivityState(this: AppStoreType) {
  const env = import.meta.env
  await useApi()
    .get(`${env.VITE_APP_AXIOS_URL}/activity/getActivityState`)
    .then((res) => {
      this.activityStatus = res
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getSlash100GBState(this: AppStoreType) {
  const env = import.meta.env
  await useApi()
    .post(`${env.VITE_APP_AXIOS_OPEN_STAGE_URL}/offerId/getActivityState`, {
      data: {
        activityId: '20230330-001Slash Redemption Code'
      }
    })
    .then((res) => {
      this.slash100GBState = res
    })
    .catch((error) => {
      console.log(error)
    })
}

export async function getChannelState(this: AppStoreType, channel: string) {
  const env = import.meta.env
  await useApi()
    .post(`${env.VITE_APP_AXIOS_OPEN_STAGE_URL}/offerId/channelState`, {
      data: {
        channel
      }
    })
    .then((res) => {
      this.JooxState = res
    })
    .catch((error) => {
      console.log(error)
    })
}

// 公告管理
export async function queryBulletin(this: AppStoreType, lang: string, routing: string) {
  const env = import.meta.env
  await useApi()
    .post(`${env.VITE_APP_AXIOS_OPEN_STAGE_URL}/bulletin/queryBulletin`, {
      data: {
        displayChannel: '',
        displayRouting: routing, // 路由名
        lang
      }
    })
    .then((res) => {
      // 跑马灯1
      // 可关闭弹窗2 不可关闭弹窗3
      this.bulletin1 = res.filter((item: any) => item.bulletinType === 1)
      this.bulletin2 = res.filter((item: any) => item.bulletinType === 2)
      this.bulletin3 = res.filter((item: any) => item.bulletinType === 3)
    })
    .catch((error) => {
      console.log(error)
    })
}
