import { Method } from 'axios'
import { request, post, get, AxiosParams, registerResponseInterceptor } from './axios'
import config from './config'
import { useStorage } from '../useStorage'
import { useJwt } from '../useJwt'

export type { AxiosParams }

const storage = useStorage()

let cacheJwt = ''

function setJwt(jwt: string) {
  let success = false
  const { leftTime } = useJwt(jwt)
  if (leftTime > 0) {
    cacheJwt = jwt
    let key = 'login-jwt'
    storage.save(key, jwt, leftTime)
    success = true
  } else {
    cacheJwt = ''
  }
  return success
}

//需要jwt验证Bear
async function authRequest<T = any>(method: Method, url: string, opts?: AxiosParams) {
  const options = Object.assign({ params: {}, headers: {}, data: {} }, opts)
  if (cacheJwt) {
    options.headers.Authorization = `Bear ${cacheJwt}`
  }
  return request<T>(method, url, options)
}

async function authGet<T = any>(url: string, opts?: AxiosParams) {
  return authRequest<T>('get', url, opts)
}

async function authPost<T = any>(url: string, opts?: AxiosParams) {
  return authRequest<T>('post', url, opts)
}

export function useApi() {
  return {
    host: config.host,
    request,
    post,
    get,
    setJwt,
    authRequest,
    authGet,
    authPost,
    registerResponseInterceptor
  }
}
