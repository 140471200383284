import config from './config'
import Axios, { Method, AxiosResponse, AxiosRequestConfig } from 'axios'
import { useUtils } from '../useUtils'

export type AxiosParams = AxiosRequestConfig & {
  refresh?: boolean
}

export type ReturnData<T = any> = {
  code: number
  msg: string
  data: T
}

const { md5 } = useUtils()

const axios = Axios.create({
  baseURL: config.host,
  headers: config.defaultHeaders,
  timeout: config.timeout
})

const cacheData: Record<string, any> = {}
const cacheDataKeys: string[] = []
const loadingList: Record<string, { fnList: any }> = {}

function getKVStr(obj: Record<string, any>) {
  return Object.entries(obj)
    .map(([k, v]) => `${k}=${JSON.stringify(v)}`)
    .sort()
    .join('&')
}

function getMD5(
  method: Method,
  url: string,
  params: Record<string, any>,
  data: Record<string, any>,
  headers: Record<string, any>
) {
  const str = [method, url, getKVStr(data), getKVStr(params), getKVStr(headers)].join('_')
  return md5(encodeURI(str), 16)
}

async function preDeal<T = any>(md5Str: string, fn: Promise<AxiosResponse<ReturnData<T>>>) {
  return fn
    .then((res) => {
      cacheData[md5Str] = res
      cacheDataKeys.push(md5Str)
      // 只缓存20条数据
      if (cacheDataKeys.length > 20) {
        const key = cacheDataKeys.shift()
        if (key) {
          delete cacheData[key]
        }
      }
      if (loadingList[md5Str]) {
        loadingList[md5Str].fnList.forEach((resolve) => {
          resolve(res)
        })
        delete loadingList[md5Str]
      }
      return res.data
    })
    .catch((e) => {
      // 接口挂了也需要清除
      delete loadingList[md5Str]
      throw e
    })
}

let responseInterceptor:
  | (<T>(res: ReturnData<T>, source?: { method: Method; url: string; opts?: AxiosParams }) => Promise<ReturnData<T>>)
  | undefined

async function realRequest<T = any>(method: Method, url: string, opts?: AxiosParams) {
  const options = Object.assign({ params: {}, headers: {}, data: {}, refresh: true }, opts)
  console.groupCollapsed('%c api请求发起', 'color: #ffffff;background-color: #8f4b2e;padding: 5px', url)
  console.log('请求方式：', method)
  console.log('请求链接：', url)
  console.log('请求参数：', options)
  console.groupEnd()
  const md5Str = getMD5(method, url, options.params, options.data, options.headers)
  let fn: Promise<AxiosResponse<ReturnData<T>>>
  if (loadingList[md5Str]) {
    // 已经有相同的接口发起了，等待结果返回
    console.log('有相同接口发起，整合成一个接口 ', `[${url}]`)
    fn = new Promise<AxiosResponse<ReturnData<T>>>((resolve) => {
      loadingList[md5Str].fnList.push(resolve)
    })
  } else {
    loadingList[md5Str] = {
      fnList: []
    }
    if (!options.refresh && cacheData[md5Str]) {
      console.log('从缓存获取数据')
      fn = new Promise<AxiosResponse<ReturnData<T>>>((resolve) => {
        resolve(cacheData[md5Str])
      })
    } else {
      fn = axios({
        ...opts,
        url,
        method,
        params: options.params,
        data: options.data,
        headers: options.headers
      }).then(async (res) => {
        return res
      })
    }
  }
  return preDeal<T>(md5Str, fn)
}

async function request<T = any>(method: Method, url: string, opts?: AxiosParams): Promise<T> {
  return realRequest<T>(method, url, opts).then(async (res) => {
    console.groupCollapsed('%c api请求结束', 'color: #ffffff;background-color: #5c7a29;padding: 5px', url)
    console.log('请求方式：', method)
    console.log('请求链接：', url)
    console.log('请求结果：', res)
    console.groupEnd()
    if (responseInterceptor) {
      res = await responseInterceptor<T>(res, { method, url, opts })
    }
    if (+res.code !== 0) {
      throw res
    }
    return res.data
  })
}

async function get<T = any>(url: string, opts?: AxiosParams) {
  return request<T>('get', url, Object.assign({ params: {}, headers: {}, refresh: true }, opts))
}

async function post<T = any>(url: string, opts?: AxiosParams) {
  return request<T>('post', url, Object.assign({ data: {}, headers: {}, refresh: true }, opts))
}

function registerResponseInterceptor(
  fn?: <T>(res: ReturnData<T>, source?: { method: Method; url: string; opts?: AxiosParams }) => Promise<ReturnData<T>>
) {
  responseInterceptor = fn
}

export { request, post, get, registerResponseInterceptor }
