import jwtDecode from 'jwt-decode'
import { useDayjs } from '../useDayjs'

export function useJwt<T = any>(jwt: string) {
  try {
    const data = jwtDecode<{
      exp: number
      data: T
    }>(jwt)
    const { dayjs, current } = useDayjs()
    //检查是否过期
    const leftTime = dayjs(data.exp * 1000).diff(current)
    return {
      data: data.data,
      leftTime
    }
  } catch (e) {}
  return {
    leftTime: 0
  }
}
