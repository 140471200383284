import { useStorage } from '../../../hook'
import { defineStore } from 'pinia'
import * as actions from './actions'
import { AppState } from './type'

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    jwt: useStorage().useLocalCache().getCache('pinia/auth/login', 'jwt'),
    txt: '',
    activityStatus: [],
    JooxState: '', // -1:未开始 0:活动中 1:结束 2:暂停
    slash100GBState: '', //-1:未开始 0:活动中 1:结束 2:暂停
    bulletin1: [], // 跑马灯
    bulletin2: [], // 可关闭
    bulletin3: [] // 不可关闭
  }),
  hydrate(state) {
    state.jwt = useStorage().useLocalCache().getCache('pinia/auth/login', 'jwt')
  },
  getters: {
    isLogin(state) {
      return !!state.jwt
    }
  },
  actions: {
    ...actions
  }
})
