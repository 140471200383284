import { useEnvConfig } from '../useEnvConfig'
const defaultHeaders = {
  'Content-Type': 'application/json'
}

// 超时时间
const timeout = 30000

export default {
  host: useEnvConfig().API_HOST,
  defaultHeaders,
  timeout
}
